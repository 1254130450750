// extracted by mini-css-extract-plugin
export var content = "gray-accordion-section-module--content--c09d0";
export var contentMobile = "gray-accordion-section-module--content-mobile--2cf72";
export var description = "gray-accordion-section-module--description--80cc7";
export var display = "gray-accordion-section-module--display--9274f";
export var image = "gray-accordion-section-module--image--38e68";
export var innerWrapper = "gray-accordion-section-module--inner-wrapper--03d7f";
export var outerWrapper = "gray-accordion-section-module--outer-wrapper--359a1";
export var tab = "gray-accordion-section-module--tab--8c906";
export var tabActive = "gray-accordion-section-module--tab-active--b9d25";
export var tabs = "gray-accordion-section-module--tabs--45bb1";
export var text = "gray-accordion-section-module--text--43e8e";
export var title = "gray-accordion-section-module--title--3cad7";
export var titleTextOuterWrapper = "gray-accordion-section-module--title-text-outer-wrapper--6a626";
export var wrapper = "gray-accordion-section-module--wrapper--c3a1a";