// extracted by mini-css-extract-plugin
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--4984b";
export var coloredBlocksLeftPart = "payload-module--colored-blocks-left-part--14714";
export var header = "payload-module--header--a972c";
export var headerContactButton = "payload-module--header-contact-button--cc2ce";
export var headerInnerWrapper = "payload-module--header-inner-wrapper--9e8f1";
export var headerOuterWrapper = "payload-module--header-outer-wrapper--8932b";
export var headerSubtitleBorder = "payload-module--header-subtitle-border--4f4b1";
export var headerSubtitleStyles = "payload-module--header-subtitle-styles--5d324";
export var headerTitleContainer = "payload-module--header-title-container--18dbc";
export var logosAndPartnersInnerWrapper = "payload-module--logos-and-partners-inner-wrapper--4e9c1";
export var videoLinkSectionBottomBlock = "payload-module--video-link-section-bottom-block--26bc3";
export var videoLinkSectionButton = "payload-module--video-link-section-button--a910a";
export var videoLinkSectionInnerWrapper = "payload-module--video-link-section-inner-wrapper--55f2e";
export var videoLinkSectionPrototype = "payload-module--video-link-section-prototype--673a2";
export var videoLinkSectionTitlesContainer = "payload-module--video-link-section-titles-container--1ff4a";