import React from 'react';

import { NewStandardSection } from '@src/components/commons';
import { Button } from '@commons/button/Button';

import Tools from '../images/tools.svg';

import * as styles from './image-text-button.module.scss';

export const ImageTextButton = () => {
  const alt = 'If the problems described are in your project we can help you solve them';
  return (
    <NewStandardSection classNames={{ innerWrapper: styles.innerWrapper }}>
      <img className={styles.image} src={Tools} alt={alt} />
      <div className={styles.content}>
        <p className={styles.text}>
          If it seems like we’ve just described the issues you face yourself, we can help you fix them.
        </p>
        <Button className={styles.button} href="#contact" label="Evaluate your idea" />
      </div>
    </NewStandardSection>
  );
};
