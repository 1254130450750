import React, { useState } from 'react';

import { NewStandardSection } from '@src/components/commons';

import GreenArrow from '@images/greenArrow.svg';
import { id, list, subtitle, title } from './payload';

import * as styles from './hover-list-section.module.scss';
import cx from 'classnames';

export const HoverListSection = () => {
  const [activeItem, setActiveItem] = useState(0);
  return (
    <NewStandardSection
      id={id}
      title={title}
      subtitle={subtitle}
      classNames={{
        innerWrapper: styles.innerWrapper,
        titlesContainer: styles.titlesContainer,
        subtitleStyles: styles.subtitle,
      }}
    >
      <ul className={styles.list}>
        {list.map((item, index) => (
          <li
            className={cx(styles.element, { [styles.active]: activeItem === index })}
            onMouseEnter={() => setActiveItem(index)}
            onClick={() => setActiveItem(index)}
            key={index}
          >
            {activeItem === index && <img className={styles.image} src={GreenArrow} alt="arrow" />}
            <span className={styles.text}>{item}</span>
          </li>
        ))}
      </ul>
    </NewStandardSection>
  );
};
