// extracted by mini-css-extract-plugin
export var button = "background-title-button-section-module--button--232fc";
export var content = "background-title-button-section-module--content--a6ba7";
export var desktops = "background-title-button-section-module--desktops--27469";
export var image = "background-title-button-section-module--image--bb12e";
export var innerWrapper = "background-title-button-section-module--inner-wrapper--81909";
export var left = "background-title-button-section-module--left--e0dcb";
export var lowerTablets = "background-title-button-section-module--lower-tablets--2fa23";
export var outerWrapper = "background-title-button-section-module--outer-wrapper--60338";
export var right = "background-title-button-section-module--right--fb07b";
export var text = "background-title-button-section-module--text--d473f";