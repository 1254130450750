import React from 'react';

export const tabs = [
  {
    title: 'Single Point of Contact communication',
    text: (
      <>
        The product design approach aims to satisfy the users with frictionless products as well as stunt stakeholders
        with straightforward communication and transparency throughout the product development process. We make sure to
        have an <b>S. P. O. C.</b> (single point of contact) on our side, most likely a product manager. They ensure the
        client understands what and why is happening in the project.
      </>
    ),
  },
  {
    title: 'Product Discovery',
    text: (
      <>
        Product discovery is the core of product design. It is a process of refining the product idea and{' '}
        <b>searching for opportunities</b> by deeply understanding real user problems. We stay curious and open-minded
        and use established discovery techniques to provide solutions that exceed users’ expectations.
      </>
    ),
  },
  {
    title: 'Strategy',
    text: (
      <>
        Strategy is what allows us to stay on the same page with our clients. We provide Design Thinking Workshops, use
        opportunity solution trees and join our competencies to stay in close cooperation with the client. Together we
        decide on KPIs and other metrics which help us <b>understand exactly the desired outcome of the project.</b>
      </>
    ),
  },
  {
    title: 'Research',
    text: (
      <>
        Product design does not exist without research. We use methodologies suited for specific projects, including
        qualitative and quantitative methods. We then perform data synthesis and data analysis to{' '}
        <b>discover customer insights</b> and provide evidence supporting product development.
      </>
    ),
  },
  {
    title: 'Analytics',
    text: (
      <>
        Extensive market research results in vast amounts of data. But data is not information - we need to conduct
        analysis to uncover the truth about users’ needs and expectations. We can turn that data into knowledge that
        allows us to make <b>data-driven decisions.</b> With data analytics on board, no quantitative data set can keep
        its secrets from us.
      </>
    ),
  },
  {
    title: 'Information Architecture',
    text: (
      <>
        A beautiful, functional building needs an architect to come to life - and the same goes for digital products.
        Our services include support in designing the <b>organization of information</b> that works seamlessly with user
        flows and guides the user to their desired outcome with no obstacles.
      </>
    ),
  },
  {
    title: 'UX Design',
    text: (
      <>
        To design a meaningful user experience, we map user workflows and transform insights into solutions. We create a{' '}
        <b>labeling and navigation system</b> that leads the users exactly where they want to be, leaving them satisfied
        and willing to come back.
      </>
    ),
  },
  {
    title: 'UI & Visual Design',
    text: (
      <>
        Our creative team invents <b>attention-grabbing interfaces</b> that suit your brand identity. We select colors,
        typefaces, and illustrations that make the look and feel of your product distinguishable and pleasing. The
        product ends up as not only functional and easy to work with but also visually appealing.
      </>
    ),
  },
  {
    title: 'Interaction Design',
    text: (
      <>
        We strive for <b>human-centric products,</b> which is why we focus on the interaction a user has with an
        interface. We design ergonomic solutions using the right words and visual representation. We find these
        solutions through iterations and continuous testing.
      </>
    ),
  },
  {
    title: 'Writing',
    text: (
      <>
        We make sure to <b>talk to the users the way they want to be talked to.</b> With UX writing, we guide them
        through the contents while keeping them engaged and answering their questions before they have a chance to ask
        them.{' '}
      </>
    ),
  },
];

export const title = 'Product design team’s competencies for your needs';
export const subtitle = (
  <>
    Our product design team consists of experienced professionals from various fields, including visual design,
    research, analytics, and architecture. Joining these competencies allows us to create outstanding products and
    brainstorm <b>innovative product ideas.</b>
  </>
);

export const id = 'expertise';
