import React from 'react';

export const title = 'Is product design the right approach?';
export const subtitle = (
  <>
    Building an excellent product is a complex process with many opportunities to make mistakes. Based on our own
    experience, we named pain points that we have encountered and now aim to eliminate. If any of these sounds like the
    reason for your challenges, <b>we are here to fix them</b> with our product design services.
  </>
);
export const list = [
  <>
    You find it hard to <b>define the scope of the project</b> and agree on the priorities.
  </>,
  <>
    You find yourself blocked by building improvements, features, or entire products that{' '}
    <b>don’t respond to user needs</b> and fail to meet business expectations.
  </>,
  <>
    Your backlog is full, but <b>you cannot verify which ideas should go first</b> and how to justify your decisions
    other than a gut feeling or a single customer request.
  </>,
  <>
    Tests ring a bell but <b>never bring valuable insights</b> into how to hone your product.
  </>,
  <>
    You suffer from <b>a lack of productive debates,</b> generate ineligible ideas and repeatedly encounter the same
    problem.
  </>,
  <>
    In the end, your team develops <b>unnecessary functionalities or products,</b> and in the worst-case scenario, are
    also painful to use.
  </>,
  <>
    You have heard about product design, and you acknowledge its power, but you <b>need a partner</b> with an array of
    competencies and know-how who will lead you.
  </>,
];

export const id = 'our-approach';
