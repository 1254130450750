import React from 'react';

import { NewStandardSection } from '@src/components/commons';

import * as styles from './title-text-section.module.scss';

export const TitleTextSection = ({ classNames: { outerWrapper } = {} }) => {
  return (
    <NewStandardSection classNames={{ outerWrapper: outerWrapper, innerWrapper: styles.innerWrapper }}>
      <div>
        <h2 className={styles.title}>
          The product <br />
          <span>design process</span>
        </h2>
      </div>
      <div className={styles.text}>
        Product design provides a <b>holistic take on digital product development.</b> It’s a systematic, iterative
        approach balancing business objectives and users’ actual needs.
        <br />
        <br />
        <b>Systematic</b> because each phase is an important step to discovering the best business opportunities.{' '}
        <b>Iterative</b> because it reveals and incorporates evidence to support business decisions.
        <br />
        <br />
        This process <b>consists of phases,</b> and each of them is an important step in discovering the best business
        opportunities.
      </div>
    </NewStandardSection>
  );
};
