import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.png';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'Product Design and Development Services - CodiLime',
  description:
    'Product design provides a holistic approach to software product development. Take a look at how we turn ideas into products that answer market needs.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: 'Product design and development services',
  subtitle: 'Our advantage: Product design with speciality in network applications',
  hasContactButton: true,
  contactButtonText: 'Evaluate your idea',
  subtitleBorderWidth: styles.headerSubtitleBorder,
  displayTopBanner: false,
  classNames: {
    outerWrapper: styles.headerOuterWrapper,
    innerWrapper: styles.headerInnerWrapper,
    subtitleStyles: styles.headerSubtitleStyles,
    titleContainer: styles.headerTitleContainer,
    contactButtonStyles: styles.headerContactButton,
    header: styles.header,
  },
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our publications about product design',
    subtitle:
      'Our product designers are eager to learn and share their knowledge. See our publications about product design in network applications.',
  },
  classNames: { customTitlesContainer: styles.blocksRedirectingToResourcesTitlesContainer },
  blocks: [
    {
      text: 'Product design vs UX design - what is the difference?',
      link: '/blog/product-design-vs-ux-design/',
    },
    {
      text: 'Why is discovery research essential for your product?',
      link: '/blog/why-is-discovery-research-essential-for-your-product/',
    },
    {
      text: 'Product discovery—how to start smart with your app development',
      link: '/blog/product-discovery-how-to-start-smart-with-your-app-development/',
    },
    {
      text: '6 pillars of Digital Product Design',
      link: '/blog/pillars-of-digital-product-design/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Product Design and Development Services',
  subtitle:
    'Product Design is only one of the areas of CodiLime’s expertise. As a technology partner, we offer a broad range of knowledge and services to provide our clients with complex solutions to their challenges. Besides design and research skills, we also offer networking expertise and engineering talents.',
  isFlippable: false,
  blocks: [
    {
      text: (
        <>
          <span>Frontend development </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.FRONTEND,
    },
    {
      text: (
        <>
          <span>DevOps services</span>
        </>
      ),
      link: paths.SERVICES.DEVOPS,
    },
    {
      text: (
        <>
          <span>Backend development </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.BACKEND,
    },
    {
      text: (
        <>
          <span>MVP development </span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.MVP,
    },
  ],
  classNames: {
    leftPart: styles.coloredBlocksLeftPart,
  },
};

export const logosAndPartnersProps = {
  title: 'Trusted by leaders',
  classNames: {
    innerWrapper: styles.logosAndPartnersOuterWrapper,
  },
};

export const videoLinkSectionProps = {
  sectionProps: {
    title: 'Check our skills in action',
  },
  classNames: {
    innerWrapper: styles.videoLinkSectionInnerWrapper,
    titlesContainer: styles.videoLinkSectionTitlesContainer,
    prototype: styles.videoLinkSectionPrototype,
    bottomBlock: styles.videoLinkSectionBottomBlock,
    button: styles.videoLinkSectionButton,
  },
  buttonText: 'Try full demo version',
  linkText: 'Click to see a clickable demo designed by our team for a network application',
};
