import React from 'react';

import { NewStandardSection } from '@src/components/commons';

import { Button } from '@commons/button/Button';

import cx from 'classnames';

import Background1 from '../images/background1.png';
import Background2 from '../images/background2.png';
import Background3 from '../images/background3.png';
import Background4 from '../images/background4.png';

import * as styles from './background-title-button-section.module.scss';

export const BackgroundTitleButtonSection = () => {
  const alt = 'Creating outstanding products';
  return (
    <NewStandardSection classNames={{ outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}>
      <img className={cx(styles.image, styles.desktops, styles.left)} src={Background1} alt={alt} />
      <img className={cx(styles.image, styles.lowerTablets, styles.left)} src={Background3} alt={alt} />
      <div className={styles.content}>
        <h2 className={styles.text}>Create outstanding products with product design</h2>
        <Button className={styles.button} href="#contact" label="Evaluate your idea" />
      </div>
      <img className={cx(styles.image, styles.desktops, styles.right)} src={Background2} alt={alt} />
      <img className={cx(styles.image, styles.lowerTablets, styles.right)} src={Background4} alt={alt} />
    </NewStandardSection>
  );
};
