import React from 'react';

export const tabs = [
  {
    title: 'Product Discovery',
    text: (
      <>
        Having an idea for improving an existing product or developing a new one is a great starting point. Our job here
        is to <b>represent a user</b> and build a bridge between your goals and solutions which are valuable for your
        customers. Thanks to that, product development is <b>driven by evidence</b> and focused on delivering value to
        users. In the discovery phase, we collect insights and clarify the unknown to verify hypotheses and turn them
        into a viable, marketable strategy.
      </>
    ),
    description:
      'In this phase, we concentrate on turning opportunities into ideas, finding product-market fit, and discovering relevant product metrics.',
  },
  {
    title: 'Design & Testing',
    text: (
      <>
        During the design and testing phase, we focus on the <b>cognitive factors of user interaction.</b> This includes
        designing proposals at different levels of fidelity: low-fidelity prototypes aimed strictly at rapid testing,
        interactive hi-fi prototypes, component design and design systems. The results of this stage can be{' '}
        <b>validated with users</b> to identify pain points and reiterate the process.
      </>
    ),
    description: (
      <>
        We end up with a backlog of ideas for new or improved functionalities based on tests’ results.
        <br />
        <br />
        We test iteratively to discover and eliminate errors before development and design effective solutions tied to
        the user&apos;s needs.
      </>
    ),
  },
  {
    title: 'Continuous Discovery',
    text: (
      <>
        Product design is not a linear process. The goal is to create a <b>product that stays relevant</b> to the target
        market - and that means it needs to adjust to shifting user needs. That’s why after we’re done with designing
        and testing, we go back to product discovery. Based on our implemented product metrics, we can leverage
        real-world users’ feedback and constantly find possibilities for improving the product.
      </>
    ),
    description: (
      <>
        The iterative cycle of product design and carefully designed user metrics will ensure your product will always{' '}
        <b>stay one step ahead</b> of the competition.
      </>
    ),
  },
];
