import React, { useState } from 'react';

import { NewStandardSection } from '@src/components/commons';
import cx from 'classnames';
import Arrow from '@images/greenArrow.svg';
import { id, tabs, title, subtitle } from './payload';

import * as styles from './drop-down-list.module.scss';

export const DropDownList = () => {
  const [activeTab, setActiveTab] = useState(0);
  const clickHandler = (index) => {
    setActiveTab(index);
  };

  const closeTab = (event) => {
    event.stopPropagation();
    setActiveTab(10);
  };
  const isFirstLeftTabActive = activeTab === 0;
  const isFirstRightTabActive = activeTab === 5;

  const divStyle = {
    justifyContent: isFirstLeftTabActive || isFirstRightTabActive ? 'flex-end' : 'flex-start',
  };

  return (
    <NewStandardSection
      id={id}
      title={title}
      subtitle={subtitle}
      classNames={{ innerWrapper: styles.innerWrapper, titlesContainer: styles.titlesContainer }}
    >
      <div className={styles.tabs} style={{ display: 'flex' }}>
        <div style={divStyle} className={styles.column}>
          {tabs.slice(0, 5).map((item, index) => (
            <div
              onClick={() => clickHandler(index)}
              className={cx(styles.tab, { [styles.active]: index === activeTab })}
              key={index}
            >
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.text}>{item.text}</p>
              <img onClick={(e) => closeTab(e)} className={styles.arrow} src={Arrow} alt="arrow" loading="lazy" />
            </div>
          ))}
        </div>
        <div style={divStyle} className={styles.column}>
          {tabs.slice(5).map((item, index) => (
            <div
              onClick={() => clickHandler(index + 5)}
              className={cx(styles.tab, { [styles.active]: index + 5 === activeTab })}
              key={index}
            >
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.text}>{item.text}</p>
              <img onClick={(e) => closeTab(e)} className={styles.arrow} src={Arrow} alt="arrow" loading="lazy" />
            </div>
          ))}
        </div>
      </div>
    </NewStandardSection>
  );
};
