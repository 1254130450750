import React from 'react';

import Layout from '@src/layouts';

import {
  BlocksRedirectingToResources,
  ColoredBlocksWithRedirects,
  DesktopHeaderLayoutA,
  MobileHeaderLayoutA,
  SliderWithPartners,
  VideoLinkSection,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  SEOProps,
  blocksRedirectingToResourcesProps,
  coloredBlocksWithRedirectsProps,
  headerProps,
  logosAndPartnersProps,
  videoLinkSectionProps,
} from '@pages-impl/product-design/payload';

import {
  SingleTestimonialGray,
  ImageTextButton,
  ArrowTextButton,
  BackgroundTitleButtonSection,
  GrayAccordionSection,
  HoverListSection,
  DropDownList,
} from '@pages-impl/product-design';

export default function ProductDesign(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Product design services for tech-driven companies"
      {...props}
    >
      <SEO {...SEOProps} />
      <DesktopHeaderLayoutA {...headerProps} displayTopBanner={false} />
      <MobileHeaderLayoutA {...headerProps} />
      <SliderWithPartners {...logosAndPartnersProps} />
      <GrayAccordionSection />
      <VideoLinkSection {...videoLinkSectionProps} />
      <BackgroundTitleButtonSection />
      <HoverListSection />
      <ImageTextButton />
      <DropDownList />
      <SingleTestimonialGray />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <ArrowTextButton />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
