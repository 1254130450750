import React from 'react';

import { NewStandardSection } from '@src/components/commons';
import Quote from '../images/quote.svg';
import Selector from '../images/selector.svg';

import * as styles from './single-testimonial-gray.module.scss';

export const SingleTestimonialGray = () => {
  return (
    <NewStandardSection
      id="testimonial"
      classNames={{ outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}
    >
      <div>
        <h2 className={styles.title}>What do our partners say about us?</h2>
      </div>
      <div className={styles.testimonial}>
        <img className={styles.quote} src={Quote} alt="quote" />
        <div className={styles.content}>
          <p className={styles.text}>
            CodiLime with its expertise in both network engineering and software development helped us build and release
            a platform for operational intelligence of digital infrastructure. CodiLime UX designers and software
            engineers did a great job to ensure great user experience of our product. Smooth communication,
            out-of-the-box thinking and reliability are those factors that also make our cooperation great.
          </p>
          <span className={styles.author}>Rudra Rugge - VP of engineering</span>
          <img src={Selector} alt="selector" />
        </div>
      </div>
    </NewStandardSection>
  );
};
