import React, { useState } from 'react';

import { NewStandardSection } from '@src/components/commons';
import { TitleTextSection } from '@pages-impl/product-design';
import cx from 'classnames';
import { tabs } from './payload';

import CloseArrow from '../images/closeArrow.svg';
import OpenArrow from '../images/openArrow.svg';

import * as styles from './gray-accordion-section.module.scss';

export const GrayAccordionSection = () => {
  const [activeTab, setActiveTab] = useState(0);
  const clickHandler = (index, event) => {
    event.stopPropagation();
    if (index === activeTab) {
      setActiveTab(null);
    } else {
      setActiveTab(index);
    }
  };
  return (
    <section id="our-process" className={styles.wrapper}>
      <TitleTextSection classNames={{ outerWrapper: styles.titleTextOuterWrapper }} />
      <NewStandardSection classNames={{ outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}>
        <div className={styles.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={'unique-tab-key' + index}
              onMouseEnter={() => setActiveTab(index)}
              onClick={() => setActiveTab(index)}
              className={cx(styles.tab, { [styles.tabActive]: index === activeTab })}
            >
              <h3 className={styles.title}>{tab.title}</h3>
              {index === activeTab && (
                <div className={styles.contentMobile}>
                  <p className={styles.text}>{tab?.text}</p>
                  <p className={styles.description}>{tab?.description}</p>
                </div>
              )}
              <img
                className={styles.image}
                onClick={(e) => clickHandler(index, e)}
                src={index === activeTab ? CloseArrow : OpenArrow}
                alt="arrow"
              />
            </div>
          ))}
        </div>
        {tabs.map((tab, index) => (
          <div key={index} className={cx(styles.content, { [styles.display]: index === activeTab })}>
            <p className={styles.text}>{tab.text}</p>
            <p className={styles.description}>{tab.description}</p>
          </div>
        ))}
      </NewStandardSection>
    </section>
  );
};
